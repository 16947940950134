import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/faith_stream"
            target="_blank"
            className="icon alt fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        {/* <li><a href="#" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li> */}
        <li>
          <a
            href="https://www.instagram.com/faith_stream/"
            target="_blank"
            className="icon alt fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; FaithStream</li>
        <li>
          Powered By:{' '}
          <a href="https://www.mydrivensolutions.com" target="_blank">
            Cloud Driven Solutions
          </a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
